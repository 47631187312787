body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.accordion :active {
  /*background: red !important;*/
}

.accordion-button {
  display: block;
  text-align: center;
  /*background-color: gray !important;*/
}

.accordion-button:focus {
  /*border-color: red;*/
  /*box-shadow: none;*/
}

.accordion-button:not(.collapsed) {
  /*color: #212529;*/
}

.icon {
  display: inline-block;
  fill: white;
  width: 23px;
  height: 23px;
}

.icon-filter {
  display: inline-block;
  fill: grey;
  width: 25px;
  height: 25px;
}

.icon-red {
  fill: red;
}

/*.icon:hover {*/
/*  width: 30px;*/
/*  height: 30px;*/
/*  margin-top: -3px;*/
/*  margin-left: -3px;*/
/*}*/

.icon:active {
  width: 28px;
  height: 28px;
  margin-top: -5px;
  margin-right: -5px;
}

.ql-container {
  background-color: white; /* Цвет фона */
}
